body {
  padding-top: 5rem; // Header height to compensate fixed behavior

  @include medium {
    padding-top: 0;
  }
}

.header {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  position: fixed;
  background-color: white;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  height: 5rem;

  @include medium {
    height: auto;
    position: static;
    margin-bottom: 3.5rem;
    padding-top: 6rem;
  }
}

.header__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
  z-index: 3;
  position: relative;
  transition: filter 0.3s;

  @include medium {
    justify-content: center;
    filter: none !important;
  }

  .header--open & {
    filter: invert(100);
  }
}

.header__homeLink {
  display: block;
}

.header__logo {
  width: 14rem;
  max-width: 80vw;

  @include medium {
    width: 22rem;
    margin: 0 auto 0.375rem;
  }
}

.header__burger {
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @include medium {
    display: none;
  }

  span {
    &,
    &::before,
    &::after {
      display: block;
      width: 1.5rem;
      height: .125rem;
      background-color: black;
      border-radius: .1875rem;
    }

    & {
      transition: background-color 0.3s;

      .header--open & {
        background-color: transparent;
      }
    }

    &::before,
    &::after {
      position: absolute;
      transition: transform 0.3s;
      transform-origin: 22% 50%;
      content: '';
    }

    &::before {
      top: 1rem;

      .header--open & {
        transform: rotate(45deg) translateX(.1875rem);
      }
    }

    &::after {
      bottom: 1rem;

      .header--open & {
        transform: rotate(-45deg) translateX(.1875rem);
      }
    }
  }
}

.header__menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: translateX(100%);
  transition: transform 0.3s;
  text-transform: uppercase;
  font-family: "Europa", Arial, sans-serif;

  @include medium {
    display: flex;
    transform: none;
    background-color: transparent;
    position: static;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.25rem;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }

  .header--open &,
  &:focus-within {
    transform: none;
  }

  ul {
    padding-top: 2rem;

    @include medium {
      padding-top: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1.25rem;
      max-width: none;
      margin: 0;

      &::after {
        content: '/';
        font-size: .625rem;
        position: relative;
        top: .0625rem;
      }
    }

    // Don't underline the home link
    & > :first-child span {
      border: none;
    }
  }

  a {
    color: white;
    font-size: 1.25rem;
    font-weight: bold;
    text-decoration: none;
    display: block;
    padding: .9375rem 0.5rem;

    @include medium {
      padding: 0;
      color: black;
      font-size: .75rem;
    }

    &:hover,
    &:focus,
    &[aria-current] {
      span {
        border-color: white;

        @include medium {
          border-color: black;
        }
      }
    }
  }

  span {
    border-bottom: 0.125rem solid transparent;
    padding-bottom: 0.25rem;
  }
}
