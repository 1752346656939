* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}
