.contact {
  max-width: 56.25rem;
  margin: auto;
  padding: 0 2rem 2rem;
  text-align: center;

  @include medium {
    margin: 1.5rem auto 0;
  }

  // Contact image
  & > picture img {
    width: 21.875rem;
    margin-bottom: 1rem;
  }

  h1 {
    font-family: "Europa";
    margin-bottom: 0.25rem;
  }

  address {
    p {
      &:first-child {
        margin-bottom: 2rem;

        @include medium {
          margin-bottom: 1rem;
        }
      }

      & > a {
        text-decoration: none;
        color: black;
        line-height: 1.5;
        display: inline-block;
        flex-direction: column;
        justify-content: center;
        display: inline-flex;
      }
    }
  }
}

.contact__cv {
  &:focus,
  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.25rem;
  }
}

.contact__social {
  margin-top: 2rem;
  display: flex;
  gap: .375rem;
  justify-content: center;

  a {
    width: 2.5rem;
    height: 2.5rem;
    display: block;
  }

  img {
    width: 2.5rem;
  }
}