.slideshow {
  margin-top: 1.5rem;
  max-width: 100%;

  @include medium {
    margin-top: 3rem;
  }

  picture + & {
    margin-top: 0.75rem;
  }

  &.js {
    .slideshow__container {
      position: relative;
      overflow: hidden;
      max-width: 100%;
    }

    .slideshow__slides {
      display: flex;
      cursor: pointer;

      img {
        margin-top: 0 !important;
      }
    }

    .slideshow__nav {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 0.25rem;

      button {
        all: unset;
        box-sizing: border-box;
        width: 1.125rem;
        height: 1.125rem;
        background-color: #dddddd;
        border-radius: 50%;
        border: .25rem solid white;
        cursor: pointer;

        &:hover,
        &:focus,
        &.active {
          background-color: #888888;
        }
      }

      img {
        display: block;
      }
    }
  }
}

.slideshow__nav {
  display: none;
}
