.list {
  --projectWidth: 13.75rem;
  display: grid;
  margin: 0 auto 4rem;
  padding: 0 3rem;
  gap: 2.5rem;

  @include medium {
    grid-template-columns: repeat(auto-fit, var(--projectWidth));
    max-width: 95rem;
    gap: 3.125rem 3.125rem;
    justify-content: center;
    margin-bottom: 7rem;
  }

  a {
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: "Europa", sans-serif;
    text-decoration: none;
    text-transform: uppercase;
    color: inherit;
    text-align: center;

    @include medium {
      text-align: left;
      font-size: .75rem;

      .animation[src] {
        display: block;
      }
    }
  }

  img {
    margin-bottom: 1rem;
    width: 100%;

    @include medium {
      width: var(--projectWidth);
      height: var(--projectWidth);
    }
  }

  .animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
  }
}
