.project {
  width: 100%;
  max-width: 60.25rem;
  margin: 0 auto 3.25rem;
  padding-bottom: 2.5rem;
  text-align: center;
  border-bottom: .0625rem solid #e0e0e0;

  @include medium {
    margin-top: 2.5rem;
    margin-bottom: 6rem;
    padding: 0 2rem 3.5rem;
  }

  h1, h2 {
    margin-top: 1.5rem;
    padding: 0 2rem;
    line-height: 1.4;
    text-transform: uppercase;
    font-family: "Europa", sans-serif;
    margin-bottom: .625rem;

    @include medium {
      margin-top: 3rem;
    }

    br {
      @include medium {
        display: none;
      }
    }
  }

  p {
    max-width: 34rem;
    padding: 0 2rem;
    margin: 0 auto;
  }

  hr {
    width: .25rem;
    height: .0625rem;
    margin: 0.75rem auto;
    border: none;
    background: black;
    transform: skew(-7deg, -7deg)
  }

  img {
    width: 100%;
  }

  picture + picture img {
    margin-top: 0.5rem;

    @include medium {
      margin-top: 0.75rem;
    }
  }

  :not(picture) + picture img {
    margin-top: 1.5rem;

    @include medium {
      margin-top: 2rem;
    }
  }
}
