@font-face {
  font-family: "Garamond";
  src: url("/assets/fonts/garamond-regular.woff2");
  font-display: swap;
}

@font-face {
  font-family: "Garamond";
  src: url("/assets/fonts/garamond-italic.woff2");
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Europa";
  src: url("/assets/fonts/europa-bold.woff2");
  font-weight: bold;
}

body {
  font-family: "Garamond", serif;
  letter-spacing: .0625rem;
}

cite, i, em {
  font-style: italic;
}

p {
  line-height: 1.5;
}

sup {
  line-height: 0;
  vertical-align: super;
  font-size: smaller;
}